<template>
  <div style="height: 70vh">
    <v-ons-dialog cancelable :visible.sync="dialog.share">
      <v-ons-card material>
        <div class="content" style="text-align: center">
          <v-ons-button
            modifier="quiet"
            style="margin: 6px 0; cursor: pointer"
            @click="share('waze')"
          >
            <img src="/img/icons/waze-icon.jpg" width="32" height="32" />
          </v-ons-button>
          <v-ons-button
            modifier="quiet"
            style="margin: 6px 0; cursor: pointer"
            @click="share('google')"
          >
            <img src="/img/icons/google-maps.png" width="32" height="32" />
          </v-ons-button>
          <v-ons-button
            modifier="quiet"
            style="margin: 6px 0; cursor: pointer"
            @click="share('copy')"
          >
            <v-ons-icon icon="md-copy"></v-ons-icon>
          </v-ons-button>
        </div>
      </v-ons-card>
    </v-ons-dialog>

    <v-ons-modal :visible.sync="dialog.report">
      <v-ons-page>
        <v-ons-card>
          <div class="title">Reporte de um problema</div>
          <div class="content">
            <v-ons-list v-show="!save">
              <v-ons-list-item
                modifier="chevron"
                tappable
                v-for="itemData of tableReport"
                :key="itemData.id"
                @click="next(itemData.id)"
                >{{ itemData.name }}</v-ons-list-item
              >
            </v-ons-list>
            <div>
              <label v-show="save" for="some-inner-input">Descrição</label
              ><br />
              <el-input
                v-show="save"
                type="textarea"
                placeholder="Informe uma descrição detalhada"
                v-model="ruleForm.description"
              >
              </el-input>
              <br />
              <v-ons-button
                v-show="save"
                style="margin: 6px 0"
                @click="doreport"
                >Enviar</v-ons-button
              >&nbsp;
              <v-ons-button
                modifier="outline"
                style="margin: 6px 0"
                @click="
                  save = false;
                  dialog.report = false;
                "
                >Cancelar</v-ons-button
              >
            </div>
          </div>
        </v-ons-card>
      </v-ons-page>
    </v-ons-modal>

    <v-ons-speed-dial position="bottom right" direction="up">
      <v-ons-fab>
        <v-ons-icon icon="md-more-horiz"></v-ons-icon>
      </v-ons-fab>

      <v-ons-speed-dial-item @click="report('generic')">
        <v-ons-icon icon="md-comment-alert"></v-ons-icon>
      </v-ons-speed-dial-item>
      <v-ons-speed-dial-item
        v-for="(icon, name) in shareItems"
        :key="name"
        @click="dialog.share = true"
      >
        <v-ons-icon :icon="icon"></v-ons-icon>
      </v-ons-speed-dial-item>
    </v-ons-speed-dial>

    <div class="finish_collect" v-if="!routeList || !routeList.length">
      <EndRoute
        :totalClients="totalCustomers"
        :totalObjects="totalObjectsCollected"
      />
    </div>
    <v-ons-list v-else-if="list">
      <v-ons-list-header>Meu itinerário</v-ons-list-header>
      <v-ons-list-item
        v-for="(itemData, index) of routeList"
        :key="itemData.id"
        :style="
          'cursor: pointer;' +
          (itemData.status === 'waiting'
            ? 'opacity: 0.6'
            : itemData.status === 'collected' ||
              itemData.status === 'not_collected'
            ? 'opacity: 0.2;'
            : '')
        "
        @click="map(itemData.lat, itemData.lng)"
      >
        <div class="_center">
          <div class="left">
            <v-ons-icon
              v-if="itemData.status === 'collected'"
              icon="fa-check"
              style="color: green"
            ></v-ons-icon>
            <v-ons-icon
              v-else-if="itemData.status === 'not_collected'"
              icon="fa-exclamation-circle"
              style="color: red"
            ></v-ons-icon>
            <i
              v-else-if="index === routeList.length - 1"
              class="el-icon-office-building"
              style="color: rgb(133, 89, 6); font-weight: bold; font-size: 18px"
            ></i>
            <v-ons-icon
              v-else
              icon="fa-arrow-circle-o-right"
              style="color: blue"
            ></v-ons-icon>
          </div>
          <span style="font-size: 17px; font-weight: 600">
            <b> Destino: </b> {{ itemData.customer?.name }}
          </span>

          <span style="font-size: 14px; font-weight: 400; margin-top: 7px">
            {{ itemData.address }} &nbsp;
          </span>
          <span style="font-size: 12px; font-weight: 400">
            Previsão chegada:
            <el-tag type="info"> {{ itemData.time }} </el-tag>
            &nbsp;
          </span>

          <div class="btns_container">
            <el-button
              v-if="itemData.status === 'pending'"
              style="font-size: 16px; padding: 8px 25px"
              type="success"
              icon="el-icon-video-play"
              @click.stop.native="start(itemData.id)"
              plain
            />

            <div
              v-else-if="
                itemData.status === 'current' &&
                distanceFromCustomer?.value <= 5000000 &&
                index !== routeList.length - 1
              "
            >
              <el-button
                type="danger"
                plain
                style="font-size: 16px; padding: 8px 25px"
                icon="el-icon-warning"
                @click.stop.native="report(itemData.id)"
              />
              <el-button
                type="warning"
                style="font-size: 16px; padding: 8px 25px"
                icon="el-icon-download"
                @click.stop.native="
                  startCollect(itemData.id, itemData.customer?.id)
                "
              />
            </div>

            <el-button
              v-if="
                itemData.status === 'current' && index === routeList.length - 1
              "
              type="success"
              style="font-size: 16px; padding: 8px 25px"
              icon="el-icon-upload2"
              @click.stop.native="deliveryObjects(itemData.id)"
            />
          </div>
        </div>
      </v-ons-list-item>
    </v-ons-list>
    <MapsView v-else :markers="markers" @back="list = true" />
  </div>
</template>

<script>
import RoutesServices from "@/services/routes/routes.services";
import OrderServicesServices from "@/services/OrderServices/order-services.services";
import EndRoute from "@/components/EndRoute.vue";
import MapsView from "./MapsView.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HomePage",
  components: { MapsView, EndRoute },
  computed: {
    ...mapGetters("socket", ["socket"]),
    ...mapGetters("geolocation", ["getLocation"]),
    ...mapGetters("geolocation", ["getDestination"]),
  },

  data() {
    return {
      routeList: [],
      dialog: {
        report: false,
        share: false,
      },
      list: true,
      save: false,
      markers: [],
      ruleForm: {
        id: "",
        description: "",
        type: "",
      },
      shareItems: {
        Compartilhar: "md-share",
      },
      tableReport: [],
      getDistanceInterval: 0,
      distanceFromCustomer: {
        value: 100,
      },
      totalObjectsCollected: 0,
      totalCustomers: 0,
    };
  },

  methods: {
    ...mapActions("geolocation", ["destination"]),
    share(type) {
      const vm = this;
      const routeData = vm.routeList.find((x) => x.status === "current");
      if (routeData) {
        if (type === "waze")
          window.open(
            `https://www.waze.com/ul?ll=${routeData.lat}%2C${routeData.lng}&navigate=yes&zoom=17`
          );
        else if (type === "google")
          window.open(
            `http://maps.google.com/?daddr=${routeData.lat},${routeData.lng}&amp;ll=`
          );
        else {
          vm.$copyText(routeData.address).then(
            () => {
              vm.$message({
                type: "success",
                message: "Texto copiado!",
              });
            },
            () => {
              vm.$message({
                type: "error",
                message: "Erro ao copiar!",
              });
            }
          );
        }
      }
    },

    map(lat, lng) {
      const vm = this;
      vm.markers = [];
      vm.markers.push(
        {
          geometry: {
            lat: vm.getLocation.lat,
            lng: vm.getLocation.lng,
          },
        },
        {
          geometry: {
            lat: lat,
            lng: lng,
          },
        }
      );
      vm.list = false;
    },

    doreport() {
      const vm = this;
      const loading = vm.$loading(vm.$options.loading);
      RoutesServices.report(vm.ruleForm)
        .then(() => {
          const index = vm.routeList.findIndex((x) => x.id === vm.ruleForm.id);
          if (index !== -1) {
            vm.routeList[index].status = "not_collected";
            if (vm.routeList[index + 1])
              vm.routeList[index + 1].status = "current";
            else vm.fetch();
          }

          vm.dialog.report = false;
          vm.save = false;
          vm.ruleForm = {
            description: "",
            id: "",
            type: "",
          };

          vm.$message({
            type: "success",
            message: "Reporte realizado com sucesso!",
          });
        })
        .catch((error) => {
          vm.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => loading.close());
    },

    report(id) {
      const vm = this;
      const loading = vm.$loading(vm.$options.loading);
      RoutesServices.reason()
        .then((response) => {
          vm.ruleForm.id = id;
          vm.tableReport = response.records;
          vm.dialog.report = true;
        })
        .catch((error) => {
          vm.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => loading.close());
    },

    next(type) {
      const vm = this;
      vm.ruleForm.type = type;
      vm.save = true;
    },

    fetch() {
      const vm = this;
      const loading = vm.$loading(vm.$options.loading);
      RoutesServices.pendencies()
        .then((response) => {
          vm.routeList = response;
          const routeData = vm.routeList.find(
            (x) => x.status === "pending" || x.status === "current"
          );
          if (routeData) {
            this.$store.commit("setTotalCustomer", vm.routeList.length);
            vm.totalCustomers = vm.routeList.length;
            this.destination({
              lat: +routeData.lat,
              lng: +routeData.lng,
            });

            const deliveryOsExits = this.$store.state.deliveryOs;
            if (!deliveryOsExits) this.createDeliveryOs();
          }
        })
        .catch((error) => {
          vm.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => loading.close());
    },
    startCollect(id, customerId) {
      this.$store.commit("setPendencyId", id);
      this.$router.push({
        name: "BeepObjectsPage",
        params: {
          id: id,
          customerId,
        },
      });
    },

    deliveryObjects(id) {
      this.$router.push({
        name: "DeliveryObjectsPage",
        params: {
          pendecy_id: id,
        },
      });
    },

    start(id) {
      const vm = this;
      if (!("geolocation" in navigator)) {
        return vm.$message({
          type: "error",
          message: "Geolocation is not available.",
          duration: 5000,
        });
      }
      const loading = vm.$loading(vm.$options.loading);
      RoutesServices.start(id, this.getLocation.lat, this.getLocation.lng)
        .then(() => {
          const index = vm.routeList.findIndex((x) => x.id === id);
          if (index !== -1) {
            vm.routeList[index].status = "current";
            vm.routeList[index].started = {
              lat: this.getLocation.lat,
              lng: this.getLocation.lng,
            };
          }
          vm.$message({
            type: "success",
            message: "Rota iniciada com sucesso!",
          });
        })
        .catch((error) => {
          vm.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => loading.close());
    },

    createDeliveryOs() {
      OrderServicesServices.createDeliveryOs()
        .then((response) => {
          this.$store.commit("setDeliveryOs", response.data);
          if (response.message)
            this.$message({
              type: "success",
              message: response.message,
            });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },

    getDistanceFromCustomer() {
      navigator.geolocation.getCurrentPosition((newPosition) => {
        console.log("get distanc3", newPosition);
      });
      /*const vm = this;
      this.$nextTick(() => {
        this.$gmapApiPromiseLazy().then(() => {newPosition
          if (vm.getLocation.lat && vm.getDestination.lat) {
            this.$options.directionsService =
              new vm.google.maps.DirectionsService();
            this.$options.directionsService.route(
              {
                origin: vm.getLocation,
                destination: vm.getDestination,
                travelMode: "DRIVING",
                waypoints: [],
              },
              (result, status) => {
                if (status === "OK") {
                  this.distanceFromCustomer = result.routes[0].legs[0].distance;
                }
              }
            );
          }
        });
      });*/
    },

    async startGetDistanceInterval() {
      this.getDistanceFromCustomer();
      this.getDistanceInterval = setInterval(async () => {
        await this.getDistanceFromCustomer();
      }, 1000 * 5);
    },

    stopGetDistanceInterval() {
      clearInterval(this.getDistanceInterval);
    },

    setup() {
      const vm = this;
      vm.socket.on("driver-transfer", () => {
        this.$ons.notification.toast(
          "Sua rota foi transferida para outro motorista",
          {
            timeout: 0,
          }
        );
        vm.fetch();
      });
      vm.socket.on("driver-add", () => {
        this.$ons.notification.toast("Novo cliente adicionado a sua rota", {
          timeout: 10000,
        });
        vm.fetch();
      });
      vm.socket.on("driver-remove", () => {
        this.$ons.notification.toast("Um endereço foi removida da sua rota", {
          timeout: 10000,
        });
        vm.fetch();
      });
    },
  },

  async mounted() {
    const vm = this;
    const interval = setInterval(() => {
      if (vm.socket) {
        vm.setup();
        clearInterval(interval);
      }
    }, 1000);
    await vm.startGetDistanceInterval();
    await this.fetch();
    this.totalObjectsCollected = this.$store.state.totalObjectsCollected;
    this.totalCustomers = this.$store.state.totalCustomers;
  },

  beforeDestroy() {
    this.stopGetDistanceInterval();
  },
};
</script>
<style scoped>
.finish_collect {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}
.finish_collect_text {
  font-size: 24px;
  color: green;
}
._center {
  display: flex;
  flex-direction: column;
}

.btns_container {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}
</style>
