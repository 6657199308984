<template>
  <div>
    <div v-if="showCamera" class="wrapper">
      <video
        class="video"
        :class="facingMode === 'user' ? 'front' : ''"
        ref="video"
      />
      <canvas style="display: none" ref="canva" />

      <div class="photo-button-container">
        <el-button
          class="photo-button"
          @click="takePhoto"
          type="success"
          icon="el-icon-camera"
        ></el-button>
      </div>
    </div>

    <div class="img_container" v-else>
      <img :src="photo" alt="foto da os" />
      <div class="btn_handle_container">
        <el-button
          type="danger"
          icon="el-icon-close"
          class="btn_handler_reset"
          @click="openCamera"
        />
        <el-button
          type="success"
          icon="el-icon-check"
          class="btn_handler_save"
          @click="save"
        />
      </div>
    </div>

    <div v-if="loading" class="camera-loading">
      <ul class="loader-circle">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</template>
<script>
import OrderServicesServices from "@/services/OrderServices/order-services.services";
import { mapGetters } from "vuex";

export default {
  name: "TakePhotoPage",
  computed: {
    ...mapGetters("geolocation", ["getLocation"]),
  },

  data() {
    return {
      showCamera: false,
      loading: false,
      photo: null,
      mediaStream: null,
      videoDevices: [],
      facingMode: "environment",
      switchingCamera: false,
      pendency_id: "",
    };
  },

  methods: {
    async startRecording(facingMode) {
      try {
        this.facingMode = facingMode;
        let video = this.$refs.video;
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: facingMode },
        });
        video.srcObject = this.mediaStream;
        return await video.play();
      } catch (error) {
        this.$message({
          type: "error",
          message: "Erro ao acessar a câmera!" + error,
        });
      }
    },

    async takePhoto() {
      try {
        this.showCamera = false;
        const video = this.$refs.video;
        const canva = this.$refs.canva;
        const width = video.videoWidth;
        const height = video.videoHeight;
        canva.width = width;
        canva.height = height;

        const ctx = canva.getContext("2d");
        ctx.save();
        ctx.drawImage(video, 0, 0);

        if (this.facingMode === "user") {
          ctx.scale(-1, 1);
          ctx.drawImage(video, width * -1, 0, width, height);
        } else {
          ctx.drawImage(video, 0, 0);
        }

        ctx.restore();
        this.photo = canva.toDataURL("image/png");
      } catch (error) {
        this.$message({
          type: "error",
          message: "Erro ao tirar foto!",
        });
      }
    },

    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      const byteArrays = [];

      for (let offset = 0; offset < b64Data.length; offset += sliceSize) {
        const slice = b64Data.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },

    async save() {
      this.loading = true;
      const ordersIds = this.$store.state.ordersIds;

      const formData = new FormData();
      const blob = this.b64toBlob(this.photo, "image/png");
      formData.append("photo", blob, "close_os_photo.png");
      formData.append("orders_ids", ordersIds);
      formData.append("lat", this.getLocation.lat);
      formData.append("lng", this.getLocation.lng);
      formData.append("id", this.pendency_id);

      OrderServicesServices.uploadPhoto(formData)
        .then((response) => {
          this.photo = null;
          this.$message({
            type: "success",
            message: response.message,
          });
          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
          this.openCamera();
        })
        .finally(() => (this.loading = false));
    },

    async openCamera() {
      this.photo = null;
      this.showCamera = true;
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.videoDevices = devices.filter((d) => d.kind === "videoinput");
      await this.startRecording(
        this.videoDevices.length === 1 ? "user" : "environment"
      );
    },
  },

  async mounted() {
    console.log("open camera");
    this.openCamera();
    this.id = this.id = this.$store.state.pendencyId;
  },
};
</script>
<style scoped>
.wrapper {
  background-color: black;
  display: grid;
  width: 100vw;
  margin: 0 auto !important;
  min-height: 100vh;
  grid-template-columns: [left] 90vw [bs] 5vw [es] 5vw [right];
  grid-template-rows: [top] 5vh [bs] 5vh [es] 60vh [middle] 10vh [bottom] 20vh [end];
  justify-items: center;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

.video {
  height: 100%;
  grid-column: left/right;
  grid-row: top / bottom;
  user-select: none;
  max-width: unset;
}

.photo-button-container {
  grid-column: left / right;
  grid-row: middle / bottom;
  z-index: 5;
  width: 100vw;
  height: 5vh;
  display: flex;
  justify-content: center;
}

.photo-button {
  width: 10vh;
  height: 10vh;
  border-radius: 100%;
  font-size: 4vh;
  position: absolute;
  bottom: 50px;
  text-align: center;
  padding: 0;
}

.img_container img {
  width: 100vw;
  height: 76vh;
  position: absolute;
  left: 0;
  top: 9vh;
  z-index: 100;
  display: flex;
}
.btn_handle_container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  left: 0;
  width: 100%;
  bottom: 8vh;
  z-index: 100;
}
.btn_handler_reset,
.btn_handler_save {
  width: 9vh;
  height: 9vh;
  border-radius: 100%;
  font-size: 24px;
  font-weight: bold;
  padding: 0;
}

.camera-loading {
  overflow: hidden;
  opacity: 0.5;
  background-color: rgb(49, 50, 50);
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
  z-index: 500;
  backdrop-filter: blur(12px);
}

.loader-circle {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  z-index: 500;
  padding: 0;
}
.loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #efa507;
  animation: preload 1s infinite;
  z-index: 500;
  top: -50%;
  border-radius: 100%;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
</style>
