import { TokenService } from "../../services/auth/storage.services";
import ApiService from "../api.services";

const RouterService = {
  pendencies: async () => {
    try {
      const response = await ApiService.get(`/api/routes/pendencies/driver`);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  me: async () => {
    try {
      const response = await ApiService.get("/api/auth/me");
      return response.data;
    } catch (error) {
      return error;
    }
  },
  profile: async (form) => {
    const data = {
      user: form,
    };
    const response = await ApiService.post("/api/users/profile", data);
    return response.data;
  },
  logout: async () => {
    try {
      console.log("TokenService.getToken()", TokenService.getToken());
      if (TokenService.getToken()) {
        const response = await ApiService.delete(`/api/routes/logout`);
        return response.data;
      }
      return true;
    } catch (error) {
      return error;
    }
  },
  start: async (id, lat, lng) => {
    try {
      const response = await ApiService.post(
        `/api/routes/pendencies/start/${id}`,
        {
          lat,
          lng,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  refresh: async (lat, lng) => {
    try {
      console.log("refresh", lat, lng);
      const response = await ApiService.post(`/api/routes/location`, {
        lat,
        lng,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  reason: async () => {
    try {
      const response = await ApiService.get(`/api/reason`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  report: async (data) => {
    try {
      const response = await ApiService.post(
        `/api/routes/pendencies/report`,
        data
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  get: async () => {
    try {
      const response = await ApiService.get("/api/routes/driver");
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getById: async (id) => {
    try {
      const response = await ApiService.get(`/api/routes/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getLocation: async (data) => {
    try {
      const response = await ApiService.post("/api/routes/location", data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export default RouterService;

export { RouterService };
