<template>
  <div style="height: 70vh">
    <el-page-header
      @back="$router.push({ name: 'BeepObjectsPage', params: { customer } })"
      tabindex="-1"
      title
      content="Detalhes O.S"
      style="margin-bottom: 15px"
    />

    <div class="servicesItemContainer" v-if="countByServices?.length">
      <el-tag
        class="serviceItemTag"
        v-for="item in countByServices"
        :key="item.name"
        type="info"
      >
        {{ item.service }}:
        {{ item.total | formatTotalByService }}
      </el-tag>
    </div>

    <el-table
      :data="preposts"
      stripe
      ref="tablePrepost"
      size="small"
      :cell-class-name="tableRowClassName"
      fit
    >
      <el-table-column prop="tag" label="Objeto" width="130" fixed />
      <el-table-column prop="service" label="Serviço" width="200" />
      <el-table-column prop="plp" label="PLP" width="130" />
      <el-table-column prop="destinyzipcode" label="CEP Destino" width="140" />
      <el-table-column prop="destinyname" label="Destinatário" width="350" />
    </el-table>
  </div>
</template>

<script>
import OrderServicesServices from "@/services/OrderServices/order-services.services";

export default {
  name: "OrderServiceDetailsPage",
  data: function () {
    return {
      customer: "",
      orderServices: [],
      preposts: [],
      order_id: null,
      countByServices: [],
    };
  },

  methods: {
    tableRowClassName({ row }) {
      return row.collect ? "success-row" : "warning-row";
    },

    getDetails(order_id) {
      OrderServicesServices.getDetails({ orders_ids: [order_id] })
        .then((response) => {
          this.preposts = [];
          this.preposts = response.data;
        })
        .catch((error) => {
          this.$notify.error({
            title: "Erro",
            message: error,
          });
        });
    },

    countTotalByService() {
      OrderServicesServices.countTotalByService({
        orders_ids: this.order_id,
      })
        .then((response) => {
          this.countByServices = null;
          this.countByServices = response;
        })
        .catch((error) => {
          this.$notify.error({
            title: "Erro",
            message: error,
          });
        });
    },
  },

  filters: {
    formatTotalByService: (data) => data.toString().padStart(2, "0"),
  },

  mounted() {
    if (this.$route.params?.order_id) {
      this.order_id = this.$route.params.order_id;
      this.getDetails(this.order_id);
      this.countTotalByService();
    }
    if (this.$route.params?.customerId) {
      this.customer = this.$route.params.customerId;
    }
  },
};
</script>
<style>
.el-table .warning-row {
  background: rgb(240, 205, 193) !important;
}

.el-table .success-row {
  background: #c5e8b2 !important;
}
.servicesItemContainer {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.serviceItemTag {
  margin-left: 5px;
  margin-bottom: 5px;
  padding: 2.5px;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
