import ApiService from "../api.services";
import { TokenService } from "../auth/storage.services";

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  login: async (username, password) => {
    const requestData = {
      username,
      password,
    };

    try {
      const response = await ApiService.post("/api/auth/login", requestData);
      return response.data;
    } catch (error) {
      throw new AuthenticationError(401, error.message);
    }
  },
  logout() {
    TokenService.removeToken();
    ApiService.removeHeader();
  },
};

export default UserService;

export { UserService, AuthenticationError };
