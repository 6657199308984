<template>
  <div style="height: 70vh">
    <el-dialog
      center
      title="Finalizar coleta"
      :fullscreen="true"
      :visible="showModal"
      :close-on-click-modal="false"
      :width="'90%'"
    >
      <div>
        <span v-if="orderData.length" class="modalMsg">
          Os objetos a seguir, que estão vinculados as O.S abaixo não foram
          bipados, caso finalize a coleta eles serão excluidos de suas
          respectivas O.Ss e só poderão ser coletados se forem relacionados à
          uma nova O.S. Deseja continuar?
        </span>
        <span v-else>Deseja finalizar a coleta?</span>
      </div>
      <!-- Objetos com os -->
      <el-row>
        <el-table
          v-if="orderData.length > 0"
          :data="orderData"
          stripe
          size="small"
          fit
          style="margin: 15px 0"
        >
          <el-table-column prop="tag" :label="'Etiqueta'" width="130" fixed />
          <el-table-column prop="service" :label="'Serviço'" width="100" />
          <el-table-column prop="order_id" :label="'O.S'" />
          <el-table-column prop="destinyzipcode" :label="'CEP Dest.'" />
          <el-table-column
            prop="destinyname"
            :label="'Destinatario'"
            width="250"
          />
        </el-table>
        <div class="btns_container">
          <el-button type="primary" @click="closeModal">VOLTAR</el-button>
          <el-button type="danger" @click="finishCollect">FINALIZAR</el-button>
        </div>
      </el-row>
      <el-dialog
        width="85%"
        :visible.sync="showConfirm"
        append-to-body
        :fullscreen="false"
        destroy-on-close
      >
        <el-row>
          <div>
            <span style="font-size: 15px; text-transform: uppercase"
              >Confirma a exclusão dos objetos? Essa ação é irreversível!</span
            >
            <div class="btns_container">
              <el-button @click="showConfirm = false" type="success"
                >VOLTAR
              </el-button>
              <el-button @click="confirmFinishCollect" type="danger"
                >CONFIRMAR
              </el-button>
            </div>
          </div>
        </el-row>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "FinishCollectModal",
  props: {
    showModal: Boolean,
    id: String,
    orderData: Array,
    prepostsWithoutOrderService: Array,
    orderServices: Array,
  },

  data() {
    return {
      showConfirm: false,
    };
  },

  methods: {
    collectObjectsWithoutOS() {
      this.$emit("collectObjectsWithoutOS");
    },

    closeModal() {
      this.$emit("closeModal");
    },

    finishCollect() {
      this.orderData.length > 0
        ? (this.showConfirm = true)
        : this.$router.push({
            name: "FinishCollectPage",
            params: {
              id: this.id,
            },
          });
    },

    confirmFinishCollect() {
      this.$emit("finishCollect");
    },
  },
};
</script>

<style scoped>
.modalMsg {
  font-size: 17px;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
}
.btns_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 25px auto;
}
</style>
