<template>
  <div class="finish_collect">
    <span class="finish_collect_text">
      Parabéns! Você finalizou sua rota !
    </span>
    <div class="box">
      <div class="card_item">
        <span>
          OBJETOS COLETADOS:
          <span class="count"> {{ totalObjects | formatCount }} </span>
        </span>
      </div>

      <div class="card_item">
        <span>
          CLIENTES VISITADOS:
          <span class="count"> {{ totalClients | formatCount }} </span>
        </span>
      </div>
    </div>
    <p class="congratulations">
      Nosso muito obrigado! <span>Equipe Mais Envios</span>
    </p>

    <el-button
      style="margin-top: 5vh; width: 25vw"
      syze="medium"
      type="warning"
      @click="exit()"
    >
      SAIR
    </el-button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "EndRoute",
  props: {
    totalClients: Number,
    totalObjects: Number,
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    exit() {
      this.$bus.$emit("logout");
      this.logout()
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {
          this.$router.replace({ name: "Login" });
        })
        .finally(() => this.$store.commit("setTotalObjectsCollected", 0));
    },
  },

  filters: {
    formatCount: (data) => data.toString().padStart(2, "0"),
  },
};
</script>
<style scoped>
.finish_collect {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 5vh;
}
.finish_collect_text {
  font-size: 20px;
  color: rgb(43, 48, 43);
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px #9e9d9d;
  text-align: center;
}
.box {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  padding: 5px 5px;
}

.card_item {
  font-size: 20px;
  color: rgb(43, 45, 43);
  padding: 20px 35px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 2px #9e9d9d;
  text-align: center;
  margin: 1vh 0;
}
.congratulations {
  font-size: 18px;
  margin-top: 5vh;
}

.count {
  color: rgb(73, 190, 73);
}

.congratulations span {
  font-style: italic;
  color: rgb(184, 126, 16);
}
</style>
