<template>
  <div id="app">
    <el-container>
      <el-header style="z-index: 1">
        <Toolbar v-if="authenticate" />
      </el-header>
      <el-main>
        <fade-transition origin="center" mode="out-in" :duration="250">
          <router-view />
        </fade-transition>
      </el-main>
      <FooterBar />
    </el-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toolbar from "./components/Toolbar.vue";
import FooterBar from "./components/FooterBar.vue";
import io from "socket.io-client";
import { TokenService } from "@/services/auth/storage.services";

export default {
  name: "app",
  computed: {
    ...mapGetters("sidebar", ["status"]),
    authenticate() {
      return this.auth;
    },
  },
  mounted() {
    const vm = this;
    if (TokenService.getToken()) vm.auth = true;
    vm.$bus.$on("logout", () => {
      vm.auth = false;
    });
    vm.$bus.$on("login", () => {
      vm.auth = true;
    });
    vm.$options.loading = {
      lock: true,
      text: "Carregando",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    };
    if (!("geolocation" in navigator)) {
      return vm.$message({
        type: "error",
        message: "Geolocation is not available.",
        duration: 5000,
      });
    }
  },
  data() {
    return {
      page: 1,
      auth: false,
      state: "initial",
      search: undefined,
      allRoutes: [],
    };
  },
  methods: {
    ...mapActions("sidebar", ["sidebarBtn"]),
    ...mapActions("geolocation", ["location"]),
    ...mapActions("socket", ["saveSocket"]),
    socket() {
      const vm = this;
      vm.socket = io(
        process.env.NODE_ENV === "development" ? "http://localhost:4001/" : "/",
        {
          path: "/mysocket",
          transports: ["websocket"],
          rejectUnauthorized: false,
          autoConnect: false,
          query: "token=" + TokenService.getToken(),
        }
      );
      vm.socket.connect();
      this.saveSocket(vm.socket);
    },
    loadItem(done) {
      const vm = this;
      vm.fetchRecords();
      setTimeout(() => {
        done();
      }, 500);
    },
  },
  created() {
    this.socket();
    this.location();
    this.sidebarBtn(false);
  },
  components: { Toolbar, FooterBar },
};
</script>

<style>
@font-face {
  font-family: "Avenit";
  src: local("Avenit"), url(./assets/fonts/AEH.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Avenit";
}

@import "./assets/style.css";

ons-splitter-side[side="left"][animation="overlay"] {
  border-right: 1px solid #bbb;
}
</style>
