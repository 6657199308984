import Vue from "vue";
import VueRouter from "vue-router";

import Login from "./../pages/Auth/Login.vue";
import HomePage from "./../pages/HomePage.vue";
import MapsPage from "./../pages/MapsPage.vue";
import OrderServiceDetailsPage from "./../pages/BeepObjects/OrderServiceDetailsPage.vue";
import BeepObjectsPage from "./../pages/BeepObjects/BeepObjectsPage.vue";
import FinishCollectPage from "./../pages/BeepObjects/FinishCollectPage.vue";
import TakePhotoPage from "./../pages/BeepObjects/TakePhotoPage.vue";
import Profile from "./../pages/Profile.vue";
import DeliveryObjectsPage from "./../pages/BeepObjects/DeliveryObjectsPage.vue";

import { TokenService } from "../services/auth/storage.services";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: Login,
    name: "Login",
    meta: {
      public: true,
    },
  },
  {
    path: "/",
    component: HomePage,
    name: "Home",
    meta: {
      title: "Inicio",
    },
  },
  {
    path: "/maps",
    component: MapsPage,
    name: "Maps",
    meta: {
      title: "Minhas rotas",
    },
  },
  {
    path: "/profile",
    component: Profile,
    name: "Profile",
    meta: {
      title: "Meus dados",
    },
  },
  {
    path: "/os/details/:order_id/:customerId",
    component: OrderServiceDetailsPage,
    name: "OrderServiceDetailsPage",
    meta: {
      title: "Detalhes da O.S.",
    },
  },
  {
    path: "/os/collect/finish",
    component: FinishCollectPage,
    name: "FinishCollectPage",
    meta: {
      title: "Finalizar Coleta",
    },
  },
  {
    path: "/os/collect/finish/take_photo",
    component: TakePhotoPage,
    name: "TakeOsPhotoPage",
    meta: {
      title: "Foto da O.S",
    },
  },
  {
    path: "/objects/beep/:customerId",
    component: BeepObjectsPage,
    name: "BeepObjectsPage",
    meta: {
      title: "Coleta",
    },
  },

  {
    path: "/objects/delivery/:pendecy_id",
    component: DeliveryObjectsPage,
    name: "DeliveryObjectsPage",
    meta: {
      title: "Encerrar Rota",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  const token = TokenService.getToken();

  const isPublic = to.matched.some((record) => record.meta.public);
  if (!isPublic && !token) {
    return next({
      name: "Login",
    });
  }
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
