<template>
  <div style="max-height: 80vh">
    <div class="headerContainer">
      <el-page-header @back="$emit('back')" title style="margin-bottom: 15px" />
    </div>
    <GmapMap
      :center="{ lat: initialPosition.lat, lng: initialPosition.lng }"
      :zoom="15"
      :options="{ disableDefaultUI: true }"
      map-type-id="roadmap"
      ref="map"
      style="width: 100%; height: 600px"
    >
      <GmapMarker
        label="Minha localização"
        v-if="gettingLocation"
        :position="{
          lat: location.lat,
          lng: location.lng,
        }"
      />
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  name: "MapsView",
  props: ["markers"],
  data() {
    return {
      place: null,
      usersName: null,
      gettingLocation: false,
      loading: true,
      initialPosition: {
        lat: 10,
        lng: 10,
      },
      location: {
        lat: 10,
        lng: 10,
      },
      zoom: 15,
      userlocation: [],
    };
  },
  computed: {
    google: gmapApi,
  },
  mounted() {
    this.generate();
  },
  methods: {
    setPlace(place) {
      console.log("setPlace", place);
      this.place = place;
    },
    generate() {
      console.log("generate", this.markers);
      this.$nextTick(() => {
        this.$gmapApiPromiseLazy().then(() => {
          // eslint-disable-next-line no-undef
          this.$options.directionsService = new google.maps.DirectionsService();
          this.$options.directionsDisplay =
            // eslint-disable-next-line no-undef
            new google.maps.DirectionsRenderer();
          this.$options.directionsDisplay.setMap(this.$refs.map.$mapObject);
          this.$options.directionsDisplay.set("directions", null);
          this.$options.directionsService.route(
            {
              origin: this.markers[0].geometry,
              destination: this.markers[this.markers.length - 1].geometry,
              travelMode: "DRIVING",
              waypoints: this.markers.map((x) => {
                return {
                  location: {
                    lat: x.geometry.lat,
                    lng: x.geometry.lng,
                  },
                  stopover: true,
                };
              }),
            },
            (result, status) => {
              if (status === "OK") {
                this.$options.directionsDisplay.setDirections(result);
              }
            }
          );
        });
      });
    },
    fetchData() {
      const vm = this;
      const maps = new gmapApi().maps;
      const geocoder = new maps.Geocoder();

      geocoder.geocode(
        { address: "89204420 - Rua Para, 245" },
        (results, status) => {
          console.log("geocoder.geocode", results, status);
        }
      );
      console.log("geocoder", geocoder);

      if (!("geolocation" in navigator)) {
        return vm.$message({
          type: "error",
          message: "Geolocation is not available.",
          duration: 5000,
        });
      }
      this.gettingLocation = false;
      vm.loading = true;
      console.log(
        "fetchData",
        navigator.geolocation.getCurrentPosition(
          (position) => {
            vm.initialPosition.lat = position.coords.latitude;
            vm.initialPosition.lng = position.coords.longitude;
            console.log(
              "start",
              vm.initialPosition.lat,
              vm.initialPosition.lng
            );
            vm.loading = false;
            console.log("this.$refs.mapRef", vm.$refs.maps);

            navigator.geolocation.watchPosition(
              (pos) => {
                console.log("watch pos", pos);
                navigator.geolocation.getCurrentPosition(
                  (newPosition) => {
                    console.log(
                      "newPosition)",
                      newPosition.coords.latitude,
                      newPosition.coords.longitude,
                      newPosition.coords.accuracy
                    );
                  },
                  (err) => {
                    console.log("newPosition err", err);
                  }
                );

                this.gettingLocation = true;
                vm.location.lat = pos.coords.latitude;
                vm.location.lng = pos.coords.longitude;
                const userData = {
                  position: {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                  },
                  userName: this.usersName,
                };
                console.log("loc", vm.location.lat, vm.location.lng);
                this.userlocation = userData;
                //this.updateRoom(userData);
              },
              (err) => {
                this.gettingLocation = false;
                this.errorStr = err.message;
              }
            );
          },
          (error) => {
            console.log("error", error);
          },
          {
            enableHighAccuracy: true,
          }
        )
      );
    },
  },
};
</script>
