const TOKEN_KEY = "ACCESS_TOKEN";

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
    console.log("remove", localStorage.getItem(TOKEN_KEY));
  },
};

export { TokenService };
