const state = {
  status: false,
};

const getters = {
  status: (state) => state.status,
};

const actions = {
  sidebarBtn({ commit }, value = true) {
    commit("changeSidebar", value);
  },
};

const mutations = {
  changeSidebar: (state, value) => {
    if (!value) {
      state.status = value;
    } else {
      if (state.status) {
        state.status = false;
      } else {
        state.status = true;
      }
    }
  },
};

export default { state, getters, actions, mutations, namespaced: true };
