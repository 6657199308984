<template>
  <v-ons-toolbar class="home-toolbar">
    <div class="left"><img :src="logo" alt="brand" width="100px" /></div>
    <div class="center">{{ title }}</div>
    <div class="right">
      <v-ons-toolbar-button @click="exit">
        <v-ons-icon icon="sign-out-alt"></v-ons-icon>
      </v-ons-toolbar-button>
    </div>
    <div class="center"></div>
  </v-ons-toolbar>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ToobarView",
  methods: {
    ...mapActions("sidebar", ["sidebarBtn"]),
    ...mapActions("auth", ["logout"]),
    exit() {
      this.$bus.$emit("logout");
      this.logout()
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {
          this.$router.replace({ name: "Login" });
        });
    },
  },
  data() {
    return {
      logo: require("../assets/logo.png"),
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
};
</script>

<style scoped>
.left {
  padding-top: 5px;
  padding-left: 15px;
  z-index: 1;
}
</style>
