import ApiService from "../../services/api.services";
import AuthServices from "../../services/auth/driver.services";
import { TokenService } from "../../services/auth/storage.services";
import RouterService from "../../services/routes/routes.services";

const state = {
  isAuth: false,
};

const getters = {
  getRoutes: (state) => state.routes,
  getAuth: () => TokenService.getToken() !== null,
};

const actions = {
  async login({ commit }, data) {
    localStorage.clear();
    return new Promise((resolve, reject) => {
      AuthServices.login(data.username, data.password).then(
        async (response) => {
          TokenService.saveToken(response);
          ApiService.setHeader();
          commit("setAuth", true);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      RouterService.logout().then(
        async () => {
          commit("setLogout");
          resolve(true);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  },
};

const mutations = {
  setAuth: (state, auth) => {
    state.isAuth = auth;
  },
  setLogout: async (state) => {
    TokenService.removeToken();
    ApiService.removeHeader();
    state.isAuth = false;
  },
};

export default { state, getters, actions, mutations, namespaced: true };
