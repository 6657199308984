import { TokenService } from "../../services/auth/storage.services";
import RouterService from "../../services/routes/routes.services";

const state = {
  geometry: { lat: null, lng: null },
  destination: { lat: null, lng: null },
  distance: 0,
};

const getters = {
  getLocation: (state) => state.geometry,
  getDestination: (state) => state.destination,
  getDistance: (state) => {
    return state.distance;
  },
};

const actions = {
  async location({ commit }) {
    navigator.geolocation.watchPosition((newPosition) => {
      if (TokenService.getToken()) {
        RouterService.refresh(
          newPosition.coords.latitude,
          newPosition.coords.longitude
        );
      }
      commit("setLocation", {
        lat: newPosition.coords.latitude,
        lng: newPosition.coords.longitude,
      });
    });
  },

  async destination({ commit }, data) {
    commit("setDestination", {
      lat: data.lat,
      lng: data.lng,
    });
  },

  async distance({ commit }, data) {
    commit("setDistance", {
      lat: data.lat,
      lng: data.lng,
    });
  },
};

const mutations = {
  setLocation: (state, location) => {
    state.geometry = location;
  },
  setDistance: (state, distance) => {
    state.distance = distance;
  },
  setDestination: (state, location) => {
    state.destination = location;
  },
};

export default { state, getters, actions, mutations, namespaced: true };
