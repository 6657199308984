<template>
  <v-ons-page>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :xs="22" :sm="12" :md="8" :lg="6" :xl="6">
        <el-card style="margin-top: 120px">
          <div class="header">
            <img
              class="banner"
              src="../../assets/logo-login.png"
              alt=""
              width="220px"
            />
          </div>
          <div>
            <p>
              <el-input type="email" placeholder="Usuário" v-model="username" />
            </p>

            <p>
              <el-input
                type="password"
                placeholder="Senha"
                v-model="password"
              />
            </p>
          </div>
          <div style="text-align: center">
            <p>
              <button @click="fetchLogin()">ENTRAR</button>
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <ons-modal direction="up">
      <Preloader />
    </ons-modal>
  </v-ons-page>
</template>

<script>
import { mapActions } from "vuex";
import Preloader from "@/components/Preloader";

export default {
  components: {
    Preloader,
  },
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async fetchLogin() {
      if (!this.username) {
        this.$ons.notification.toast(
          "Você precisa informar um nome de usuário",
          {
            timeout: 2000,
          }
        );
        return;
      }

      if (!this.password) {
        this.$ons.notification.toast("Você precisa informar uma senha", {
          timeout: 2000,
        });
        return;
      }

      const modal = this.$el.querySelector("ons-modal");
      modal.show();

      this.login({ username: this.username, password: this.password })
        .then(() => {
          this.$bus.$emit("login");
          this.$router.push({ name: "Home" });
          this.$ons.notification.toast("Motorista logado com sucesso!", {
            timeout: 2000,
          });

          setTimeout(() => {
            modal.hide();
          }, 1500);
        })
        .catch(() => {
          setTimeout(() => {
            modal.hide();
            this.$ons.notification.toast("Usuário e senha são inválidos!", {
              timeout: 2000,
            });
          }, 1500);
        });
    },
  },
  created() {
    if (localStorage.ACCESS_TOKEN) {
      this.$router.push({ name: "Home" }).catch(() => {});
    }
  },
};
</script>

<style scoped>
.header {
  text-align: center;
}

img {
  max-width: 300px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

input {
  background: #fff;
  border: none;
  width: 250px;
  height: 10px;
  padding: 20px;
  border-radius: 10px;
  font-size: 15px;
}

button {
  background: #22418d;
  color: #fff;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 10px;
}
</style>
