/* eslint-disable no-console */

import { register } from "register-service-worker";
import RoutesServices from "@/services/routes/routes.services";
import { TokenService } from "@/services/auth/storage.services";

//if (process.env.NODE_ENV === "production") {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    if (TokenService.getToken()) {
      navigator.geolocation.watchPosition((pos) => {
        console.log("watch pos", pos);
        navigator.geolocation.getCurrentPosition(
          (newPosition) => {
            RoutesServices.refresh(
              newPosition.coords.latitude,
              newPosition.coords.longitude
            );
          },
          (err) => {
            console.log("newPosition err", err);
          }
        );
      });
    }
  },
  registered() {
    console.log("register", register);
    console.log("Service worker has been registered.");
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated() {
    console.log("New content is available; please refresh.");
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});
//}
