/* eslint-disable no-useless-catch */
import ApiService from "../api.services";
const OrderServicesServices = {
  findOpenByCustomer: async (customer_id) => {
    try {
      const response = await ApiService.get(
        `/api/os/find/bycustomer/${customer_id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createDeliveryOs: async () => {
    try {
      const response = await ApiService.post(`/api/os/delivery`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getDetails: async (data) => {
    try {
      const response = await ApiService.post(`/api/os/objects/details`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  validateTag: async (tag) => {
    try {
      const response = await ApiService.get(`/api/os/tag/validate/${tag}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  collectObjects: async (data) => {
    try {
      const response = await ApiService.post(`/api/os/objects/collect`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  removeCollectObjects: async (tag) => {
    try {
      const response = await ApiService.delete(
        `/api/os/objects/collect/${tag}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  removeObjects: async (data) => {
    try {
      const response = await ApiService.post(`/api/os/objects/remove`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  /* eslint-disable */
  uploadSignature: async (formData) => {
    try {
      const response = await ApiService.postUploadData("/api/os/signatures/upload", formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  uploadPhoto: async (formData) => {
    try {
      const response = await ApiService.postUploadData("/api/os/photo/upload", formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /* eslint-disable */
  deliveryObjects: async (formData) => {
    try {
      const response = await ApiService.postUploadData("/api/os/objects/beep/delivery", formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  countTotalByService: async ({ orders_ids, delivery_os_id }) => {
    try {
      const response = await ApiService.filter(
        "/api/os/count/totalservices/byorders",
        { params: { delivery_os_id, orders_ids } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default OrderServicesServices;
