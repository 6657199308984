/* eslint-disable no-unused-vars */
import Vue from "vue";
import App from "./App.vue";
import locale from "element-ui/lib/locale/lang/pt-br";
import "./registerServiceWorker";
import router from "./router";
import EventBus from "./plugin/event.bus.js";
import store from "./store";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import "element-ui/lib/theme-chalk/index.css";
import VueSignaturePad from "vue-signature-pad";

import VueOnsen from "vue-onsenui";
import ElementUI from "element-ui";
import ApiService from "./services/api.services";
import * as VueGoogleMaps from "vue2-google-maps";
import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

Vue.use(VueOnsen);
Vue.use(ElementUI, { locale });
Vue.prototype.$bus = EventBus;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCs3kQGGxrjTru42OD2RKvo83hfeNtAoM0",
    language: "pt-BR",
    region: "BR",
    libraries: "places,drawing,visualization,routes",
  },
  installComponents: true,
});
console.log("NODE_ENV", process.env.NODE_ENV);
Vue.use(VueSignaturePad);

// 'pKPIEQ.D64ymA:ZTk2SKNJ4tXXfRZ8LUxMKl6vGeQMBDYZuOveWPCnBcc'

Vue.config.productionTip = false;

if (process.env.NODE_ENV === "development") {
  ApiService.init("http://localhost:3000");
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
