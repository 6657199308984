<template>
  <el-footer style="z-index: 1">
    <div class="tabbar tabbar--bottom tabbar--bottom-border">
      <label class="tabbar__item tabbar--bottom-border__item">
        <input
          @click="changePage('/')"
          type="radio"
          name="bottom-tabbar-b"
          checked="checked"
        />
        <el-button
          icon="el-icon-s-home"
          class="tabbar__button tabbar--bottom-border__button"
        ></el-button>
      </label>

      <label class="tabbar__item tabbar--bottom-border__item">
        <input
          @click="changePage('/profile')"
          type="radio"
          name="bottom-tabbar-b"
        />
        <el-button
          icon="el-icon-s-custom"
          class="tabbar__button tabbar--bottom-border__button"
        ></el-button>
      </label>
    </div>
  </el-footer>
</template>

<script>
import RouterPage from "@/pages/Routes/RouterPage.vue";

export default {
  data() {
    return {
      activeIndex: 0,
      tabs: [{ label: "Home", page: RouterPage }],
    };
  },
  methods: {
    md() {
      console.log(
        "android",
        this.$ons,
        this.$ons.platform.isAndroid(),
        this.$ons.platform.getIOSDevice(),
        this.$ons.platform.getMobileOS(),
        this.$ons.platform.isChrome(),
        this.$ons.platform.isWebView()
      );
      return this.$ons.platform.isAndroid();
    },
    changePage(name) {
      this.$router.push(name).catch(() => {});
    },
  },
};
</script>
