<template>
  <div style="height: 70vh">
    <div class="headerContainer">
      <el-page-header
        @back="$router.push({ name: 'Home' })"
        style="margin-bottom: 15px"
      >
        <template slot="title">
          Objetos coletados
          <span class="notification notification--material">{{
            total
          }}</span></template
        >
      </el-page-header>
      <el-button @click="showFinishCollectModal()" type="success"
        >Finalizar Coleta</el-button
      >
    </div>
    <el-input
      :placeholder="'Etiqueta'"
      prefix-icon="el-icon-plus"
      v-on:keyup.native.enter="add()"
      v-model="tag"
    />
    <el-collapse style="margin-top: 5px" v-model="active">
      <el-collapse-item title="Objetos coletados" name="1">
        <el-table
          :data="objectsBeeped"
          stripe
          size="small"
          :max-height="300"
          fit
        >
          <el-table-column label="#" align="center">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                :disabled="scope.row.status === 'wait'"
                @click="remove(scope.row.tag)"
                circle
                style="padding: 6px"
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column prop="tag" :label="'Etiqueta'" width="150">
            <template slot-scope="scope">
              {{ scope.row.tag }}
              &nbsp;
              <i v-if="scope.row.status === 'wait'" class="el-icon-loading"></i>
              <i
                style="color: #67c23a"
                v-else-if="scope.row.status === 'success'"
                class="el-icon-check"
              ></i>
              <i style="color: #f56c6c" v-else class="el-icon-close"></i>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="plp" label="PLP" width="130" />
          <el-table-column prop="order_id" label="O.S" align="center" />
          <el-table-column prop="service" label="Serviço" />
        </el-table>
      </el-collapse-item>
      <el-collapse-item title="Lista de O.S do Cliente">
        <el-table
          :data="orderServices"
          stripe
          ref="tableOs"
          :max-height="410"
          fit
          size="small"
        >
          <el-table-column prop="id" label="O.S." align="center" />
          <el-table-column label="Objetos" prop="quantity" align="center" />
          <el-table-column
            label="Coletados"
            prop="totalBeeped"
            align="center"
          />
          <el-table-column label="#" align="center">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="Detalhes da O.S."
                placement="left-start"
              >
                <el-button
                  circle
                  type="primary"
                  icon="el-icon-plus"
                  @click="orderServiceDetails(scope.row.id)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>

    <FinishCollectModal
      :orderData="notBeeped"
      :show-modal="showFinishModal"
      :prepostsWithoutOrderService="prepostsWithoutOrderService"
      :orderServices="orderServices"
      :id="id"
      @closeModal="closeFinishModal"
      @finishCollect="finishCollect"
      @collectObjectsWithoutOS="collectObjectsWithoutOS"
    />
  </div>
</template>

<script>
import OrderServicesServices from "@/services/OrderServices/order-services.services";
import FinishCollectModal from "./FinishCollectModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "BeepObjectsPage",
  components: {
    FinishCollectModal,
  },

  computed: {
    ...mapGetters("geolocation", ["getLocation"]),
    ...mapGetters("geolocation", ["getDestination"]),
  },

  data: function () {
    return {
      customer: "",
      active: "1",
      id: "",
      orderMain: 0,
      orderServices: [],
      preposts: [],
      objectsBeeped: [],
      notBeeped: [],
      prepostsWithoutOrderService: [],
      tag: "",
      showFinishModal: false,
      total: 0,
    };
  },

  methods: {
    add() {
      if (!this.tag)
        return this.$message({
          type: "error",
          message: "Etiqueta não informada",
        });

      const findIndex = this.objectsBeeped.findIndex((x) => x.tag === this.tag);
      if (findIndex !== -1) {
        return this.$message({
          type: "error",
          message: "Etiqueta já bipada",
        });
      }
      this.$store.commit("setTotalObjectsCollected", 1);

      /*const tagIndex = this.objectsBeeped.findIndex((x) => x.tag === this.tag);
      const notBeepedIndex = this.notBeeped.findIndex(
        (x) => x.tag === this.tag
      );
      const tagWithoutOSIndex = this.prepostsWithoutOrderService.findIndex(
        (i) => i.tag === this.tag
      );*/

      /*if (tagIndex !== -1 || tagWithoutOSIndex !== -1) {
        this.tag = "";
        return this.$message({
          type: "error",
          message: "Etiqueta já bipada",
        });
      }*/

      //if (notBeepedIndex !== -1) this.notBeeped.splice(notBeepedIndex, 1);

      this.validateTag(this.tag);
      this.tag = "";
    },
    remove(tag) {
      const findIndex = this.objectsBeeped.findIndex((x) => x.tag === tag);
      this.objectsBeeped.splice(findIndex, 1);
      this.total--;
      OrderServicesServices.removeCollectObjects(tag);
    },
    closeFinishModal() {
      this.showFinishModal = false;
      for (
        let currentIndex = 0;
        currentIndex < this.objectsBeeped.length;
        currentIndex++
      ) {
        console.log("currentIndex", currentIndex);
        for (
          let indexObjectCompared = currentIndex + 1;
          indexObjectCompared < this.objectsBeeped.length;
          indexObjectCompared++
        ) {
          if (
            this.objectsBeeped[currentIndex].tag ===
            this.objectsBeeped[indexObjectCompared].tag
          ) {
            this.objectsBeeped.splice(indexObjectCompared, 1);
            indexObjectCompared--;
          }
        }
      }
    },

    async validateTag(tag) {
      OrderServicesServices.validateTag(tag)
        .then(async (response) => {
          console.log("response", response);
          this.total++;
          this.objectsBeeped.unshift({
            tag: response.data.tag,
            status: "success",
            order_id: response.data.orderservice,
            plp: response.data.plp,
            service: response.data.service,
          });
          console.log("collectObjects");
          await this.collectObjects(
            tag,
            response.data.orderservice,
            response.data.plp
          );
          const orderServiceItem = this.orderServices.find(
            (os) => os.id === response.data.orderservice
          );
          if (orderServiceItem) orderServiceItem.totalBeeped++;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },

    async collectObjects(tag, order_id, plp) {
      const index = this.objectsBeeped.findIndex((x) => x.tag === tag);

      const data = {
        event_type: "COLECT_CLIENT",
        lat: this.getLocation.lat,
        long: this.getLocation.lng,
        id: this.$store.state.pendencyId,
        customer: this.customer,
        pendency: this.id,
        tags: [
          {
            tag,
            order_id: order_id || this.orderMain,
          },
        ],
      };
      console.log("call collectObjects", data);

      OrderServicesServices.collectObjects(data)
        .then((response) => {
          console.log("call collectObjects response", response);
          if (response.status === 200) {
            this.objectsBeeped[index].status = "success";
            this.objectsBeeped[index].order_id = order_id;
            this.objectsBeeped[index].plp = plp;
          }
          this.$message({
            type: "success",
            message: "Etiqueta  bipada",
          });
        })
        .catch((error) => {
          this.objectsBeeped[index].status = "danger";
          this.$message({
            type: "error",
            message: error,
          });
        });
    },

    async getOsOpenByCustomer() {
      if (!this.customer) return true;
      const loading = this.$loading(this.$options.loading);
      OrderServicesServices.findOpenByCustomer(this.customer)
        .then((response) => {
          const orders_ids = response.map((i) => i.id);
          if (response.length === 0) {
            return this.$message({
              type: "error",
              message: "Cliente sem OS para coleta",
            });
          }
          this.orderMain = orders_ids[0];
          this.getOsDetails({ orders_ids });
          response.map((item) => {
            this.orderServices.push({
              id: item.id,
              quantity: item.quantity,
              totalBeeped: 0,
            });
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => loading.close());
    },

    async collectObjectsWithoutOS() {
      for (const item of this.prepostsWithoutOrderService) {
        if (!item.order_id)
          return this.$message({
            type: "error",
            message: `Relacione O.S para a etiqueta ${item.tag}`,
          });
      }

      console.log("collectObjectsWithoutOS");
      const loading = this.$loading(this.$options.loading);
      const data = {
        tags: this.prepostsWithoutOrderService,
        customer: this.customer,
        event_type: "COLECT_CLIENT",
        lat: this.getLocation.lat,
        long: this.getLocation.lng,
      };

      OrderServicesServices.collectObjects(data)
        .then(() => {
          for (const item of this.prepostsWithoutOrderService) {
            this.objectsBeeped.unshift({
              tag: item.tag,
              status: "success",
              order_id: item.order_id,
              plp: item.plp,
            });

            const orderServiceItem = this.orderServices.find(
              (os) => os.id === item.order_id
            );
            if (orderServiceItem) {
              orderServiceItem.totalBeeped++;
              orderServiceItem.quantity++;
            }
          }
          this.prepostsWithoutOrderService = [];
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => {
          console.log("collectObjectsWithoutOS fim");
          loading.close();
        });
    },

    async getOsDetails(data) {
      const loading = this.$loading(this.$options.loading);
      if (data.orders_ids.length === 0) return true;
      console.log("detail", data);
      OrderServicesServices.getDetails(data)
        .then((response) => {
          if (response.message)
            return this.$message({
              type: "warning",
              message: response.message,
            });

          this.notBeeped = [];
          const prepostsNotBeeped = response.data.filter(
            (item) => !item.collect
          );
          this.notBeeped.push(...prepostsNotBeeped);

          const prepostsBeeped = response.data?.filter((item) => item.collect);

          for (const item of prepostsBeeped) {
            const orderServiceItem = this.orderServices.find(
              (os) => os.id === item.order_id
            );
            if (orderServiceItem) orderServiceItem.totalBeeped++;

            this.objectsBeeped.push({
              tag: item.tag,
              status: "success",
              order_id: item.order_id,
              plp: item.plp,
            });
          }
        })
        .catch((error) => {
          this.$notify.error({
            title: "Erro",
            message: error,
          });
        })
        .finally(() => loading.close());
    },

    async finishCollect() {
      const vm = this;
      if (this.notBeeped?.length === 0) {
        console.log("notBeeped");
        this.$store.commit("clearBeepObjects");
        return this.$router.push({
          name: "FinishCollectPage",
        });
      }

      const tags = this.notBeeped.map((item) => ({
        tag: item.tag,
        order_id: item.order_id,
      }));

      const data = {
        id: this.id,
        tags,
        lat: this.getLocation.lat,
        long: this.getLocation.lng,
      };
      console.log("data", data);
      if (!data.tags || data.tags.length === 0) {
        this.$message({
          type: "success",
          message: `Coleta finalizada com sucesso!`,
        });

        console.log("dat.tags");
        return this.$router.push({
          name: "FinishCollectPage",
          params: {
            id: vm.id,
          },
        });
      }

      console.log("remove");
      const loading = this.$loading(this.$options.loading);
      OrderServicesServices.removeObjects(data)
        .then(() => {
          this.$message({
            type: "success",
            message: `Coleta finalizada com sucesso!`,
          });
          console.log("remove");
          this.$router.push({
            name: "FinishCollectPage",
            params: {
              id: vm.id,
            },
          });
        })
        .catch((error) => {
          this.$notify.error({
            title: "Erro",
            message: error,
          });
        })
        .finally(() => {
          console.log("remove close");
          loading.close();
          this.$store.commit("clearBeepObjects");
        });
    },

    async showFinishCollectModal() {
      const ordersIds = this.orderServices.map((os) => os.id);
      await this.getOsDetails({ orders_ids: ordersIds });
      this.$store.commit("setOrdersIds", ordersIds);
      this.showFinishModal = true;
    },

    orderServiceDetails(order_id) {
      this.$store.commit("setBeepObjectsData", {
        objectsBeeped: this.objectsBeeped,
        orderServices: this.orderServices,
        prepostsWithoutOrderService: this.prepostsWithoutOrderService,
      });
      this.$router.push({
        name: "OrderServiceDetailsPage",
        params: {
          order_id,
        },
      });
    },

    handleCachedData() {
      const { objectsBeeped, prepostsWithoutOrderService, orderServices } =
        this.$store.state.beepObjectsData;

      console.log(
        "this.$store.state.beepObjectsData",
        this.$store.state.beepObjectsData,
        orderServices?.length
      );
      if (objectsBeeped?.length) this.objectsBeeped = objectsBeeped;
      if (prepostsWithoutOrderService?.length) {
        this.prepostsWithoutOrderService = prepostsWithoutOrderService;
      }

      orderServices?.length
        ? (this.orderServices = orderServices)
        : this.getOsOpenByCustomer();
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    if (this.$route.params?.customerId) {
      this.customer = this.$route.params.customerId;
    }
    this.handleCachedData();
  },
};
</script>
<style scoped>
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.osListContainer {
  display: flex;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
}
</style>
