<template>
  <el-card>
    <el-page-header
      @back="$router.push('/')"
      title
      content="Meus dados"
    ></el-page-header>
    <br />
    <el-form :model="ruleForm" ref="ruleForm" label-width="70px">
      <el-row>
        <el-col :xs="24" :sm="18" :md="14" :lg="12" :xl="10">
          <el-form-item label="Nome" prop="name" class="is-required">
            <el-input v-model="ruleForm.name" maxlength="80"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="6" :md="10" :lg="8" :xl="5">
          <el-form-item label="Login" prop="username" class="is-required">
            <el-input v-model="ruleForm.username" maxlength="32"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="6" :md="10" :lg="8" :xl="5">
          <el-form-item label="Senha" prop="username" class="is-required">
            <el-input
              type="password"
              v-model="ruleForm.password"
              maxlength="32"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="E-mail" prop="mail" class="is-required">
            <el-input v-model="ruleForm.mail" maxlength="120"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <br />
      <el-row justify="center">
        <el-col style="display: flex; justify-content: space-between">
          <el-button @click="$router.push('/')">Voltar</el-button>
          <el-button type="primary" autofocus @click="submitForm('ruleForm')">
            Atualizar
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import RoutesServices from "@/services/routes/routes.services";
export default {
  name: "ProfileView",
  data() {
    return {
      ruleForm: {
        name: "",
        username: "",
        password: "",
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      const vm = this;
      const loading = vm.$loading(vm.$options.loading);
      RoutesServices.me()
        .then((response) => {
          console.log("response", response);
          vm.ruleForm = response;
        })
        .catch((error) => {
          vm.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => loading.close());
    },
    submitForm() {
      const vm = this;
      const loading = vm.$loading(vm.$options.loading);
      RoutesServices.profile(vm.ruleForm)
        .then(() => {
          vm.$message({
            type: "success",
            message: "Dados atualizados com sucesso!",
          });
        })
        .catch((error) => {
          vm.$message({
            type: "error",
            message: error,
          });
        })
        .finally(() => loading.close());
    },
  },
};
</script>
