<template>
  <div style="text-align: center">
    <p>
      <ons-icon icon="md-spinner" size="28px" spin></ons-icon
      >&nbsp;&nbsp;Carregando...
    </p>
  </div>
</template>

<script>
export default {
  name: "PreloaderComponent",
};
</script>

<style></style>
