import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  socket: null,
};

const getters = {
  socket: (status) => {
    return status.socket;
  },
};

const actions = {
  saveSocket({ commit }, data) {
    commit("saveSocket", data);
  },
};

const mutations = {
  saveSocket(state, data) {
    state.socket = data;
  },
};

const socket = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default socket;

export { socket };
