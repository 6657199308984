<template>
  <GmapMap
    :center="{ lat: initialPosition.lat, lng: initialPosition.lng }"
    :zoom="15"
    :options="{ disableDefaultUI: true }"
    map-type-id="roadmap"
    ref="map"
    style="width: 100%; height: 600px"
  >
    <GmapMarker
      label="Minha localização"
      v-if="gettingLocation"
      :position="{
        lat: location.lat,
        lng: location.lng,
      }"
    />
  </GmapMap>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  name: "MapsView",
  props: ["markers"],
  data() {
    return {
      place: null,
      usersName: null,
      gettingLocation: false,
      loading: true,
      initialPosition: {
        lat: 10,
        lng: 10,
      },
      location: {
        lat: 10,
        lng: 10,
      },
      zoom: 15,
      userlocation: [],
    };
  },
  computed: {
    google: gmapApi,
  },
  mounted() {
    this.generate();
  },
  methods: {
    setPlace(place) {
      console.log("setPlace", place);
      this.place = place;
    },
    generate() {
      console.log("generate", this.markers);
      this.$nextTick(() => {
        this.$gmapApiPromiseLazy().then(() => {
          // eslint-disable-next-line no-undef
          this.$options.directionsService = new google.maps.DirectionsService();
          this.$options.directionsDisplay =
            // eslint-disable-next-line no-undef
            new google.maps.DirectionsRenderer();
          this.$options.directionsDisplay.setMap(this.$refs.map.$mapObject);
          this.$options.directionsDisplay.set("directions", null);
          this.$options.directionsService.route(
            {
              origin: this.markers[0].geometry,
              destination: this.markers[this.markers.length - 1].geometry,
              travelMode: "DRIVING",
              waypoints: this.markers.map((x) => {
                return {
                  location: {
                    lat: x.geometry.lat,
                    lng: x.geometry.lng,
                  },
                  stopover: true,
                };
              }),
            },
            (result, status) => {
              if (status === "OK") {
                this.$options.directionsDisplay.setDirections(result);
              }
            }
          );
        });
      });
    },
  },
};
</script>
