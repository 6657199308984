import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import geolocation from "./modules/geolocation";
import sidebar from "./modules/sidebar";
import socket from "./modules/socket.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    beepObjectsData: {
      objectsBeeped: [],
      prepostsWithoutOrderService: [],
      orderServices: [],
    },
    ordersIds: null,
    deliveryOs: null,
    pendencyId: null,
    totalObjectsCollected: 0,
    totalCustomers: 0,
  },
  getters: {},
  mutations: {
    setBeepObjectsData(state, data) {
      state.beepObjectsData = data;
    },

    clearBeepObjects(state) {
      state.beepObjectsData = {
        objectsBeeped: [],
        prepostsWithoutOrderService: [],
        orderServices: [],
      };
    },

    setOrdersIds(state, data) {
      state.ordersIds = data;
    },

    clearOrdersIds(state) {
      state.ordersIds = null;
    },

    setDeliveryOs(state, data) {
      state.deliveryOs = data;
    },

    setPendencyId(state, data) {
      state.pendencyId = data;
    },

    setTotalCustomer(state, data) {
      state.totalCustomers = data;
    },

    setTotalObjectsCollected(state, data) {
      state.totalObjectsCollected += data;
    },
  },
  actions: {},
  modules: {
    sidebar,
    auth,
    geolocation,
    socket,
  },
});
